import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            authorize: [],
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/dashboard/create-application",
        name: "ApplicationCreate",
        component: () =>
            import(
                /* webpackChunkName: "applicationcreate" */ "../views/Dashboard/Student/Application/ApplicationCreate.vue"
            ),

        meta: {
            authorize: ["student"],
        },
    },
    {
        // так как заявка может только одна, то и айдишник принимать не нужно
        path: "/dashboard/application-details",
        name: "StudentApplicationDetails",
        component: () =>
            import(
                /* webpackChunkName: "applicationdetails" */ "../views/Dashboard/Student/Application/ApplicationDetails.vue"
            ),

        meta: {
            authorize: ["student"],
        },
    },
    // декан
    {
        path: "/dashboard/applications/update/:id",
        name: "ApplicationUpdate",
        component: () =>
            import(
                /* webpackChunkName: "applicationupdate" */ "../views/Dashboard/Management/ApplicationUpdate.vue"
            ),
        props: true,
        meta: {
            authorize: ["dean", "secretary"],
        },
    },
    // секретарь
    {
        path: "/dashboard/submission-period",
        name: "SubmissionPeriodUpdate",
        component: () =>
            import(
                /* webpackChunkName: "submissionperiodupdate" */ "../views/Dashboard/Secretary/SubmissionPeriodUpdate.vue"
            ),
        meta: {
            authorize: ["secretary"],
        },
    },
    // комиссия
        {
        path: "/dashboard/applications/read/:id",
        name: "ApplicationReadOnly",
        component: () =>
            import(
                /* webpackChunkName: "applicationreadonly" */ "../views/Dashboard/Management/ApplicationReadOnly.vue"
            ),
        props: true,
        meta: {
            authorize: ["commission"],
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const { authorize } = to.meta;
    const currentUser = store.getters["auth/user"];

    if (authorize) {
        if (!currentUser) {
            return next({ path: "/login", query: { returnUrl: to.path } });
        }

        if (authorize.length && !authorize.includes(currentUser.role)) {
            return next({ path: "/" });
        }
    }
    next();
});

export default router;
