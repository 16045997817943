<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavbarComponent",
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  mounted() {
    this.language = localStorage.getItem("language") || "kk";
  },
  data() {
    return {
      language: "kk",
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    submitLogout() {
      this.logout().then(() => {
        this.$router.replace({
          name: "Login",
        });
      });
    },
    changeLanguage() {
      localStorage.setItem("language", this.language);
      this.$i18n.locale = this.language;
      // window.location.reload();
    },
  },
};
</script>

<template>
  <div class="container mb-6">
    <b-navbar>
      <template v-if="isAuthenticated" #start>
        <b-navbar-item tag="router-link" to="/">
          {{ $t("home") }}
        </b-navbar-item>
        <b-navbar-item
          v-if="user.role === 'secretary'"
          tag="router-link"
          to="/dashboard/submission-period"
        >
          {{ $t("period_date") }}
        </b-navbar-item>
      </template>

      <template #end>
        <template v-if="isAuthenticated">
          <b-navbar-item>
            <div>
              {{ user.first_name }} {{ user.last_name }} -
              <template v-if="user.role === 'student'">студент</template>
              <template v-else-if="user.role === 'dean'">декан</template>
              <template v-else-if="user.role === 'secretary'">{{ $t("secretary") }}</template>
              <template v-else-if="user.role === 'commission'">комиссия</template>
            </div>
          </b-navbar-item>
          <b-navbar-item @click="submitLogout()">
            <div class="is-flex">
              <b-icon icon="logout" class="mr-1"></b-icon>
              {{ $t("logout") }}
            </div>
          </b-navbar-item>
        </template>
        <b-navbar-item>
          <b-field>
            <b-radio-button
              v-model="language"
              @input="changeLanguage()"
              value="kk"
              native-value="kk"
              type="is-primary is-light is-outlined"
            >
              <b-icon v-if="language === 'kk'" icon="check"></b-icon>
              <span>KK</span>
            </b-radio-button>

            <b-radio-button
              v-model="language"
              @input="changeLanguage()"
              value="ru"
              native-value="ru"
              type="is-primary is-light is-outlined"
            >
              <b-icon v-if="language === 'ru'" icon="check"></b-icon>
              <span> RU </span>
            </b-radio-button>
          </b-field>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>