<script>
import { ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {
    ValidationObserver,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      errorMsg: "",
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    submitLogin() {
      this.isLoading = true;
      this.login(this.form)
        .then(() => {
          this.$router.replace({
            name: "Home",
          });
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
          console.log(e);
          if (e.response.status === 401) {
            this.errorMsg = this.$t("invalid_credentials");
          } else {
            this.errorMsg = "Произошла ошибка";
          }
        });
    },
  },
};
</script>

<template>
  <div class="container column is-half section is-medium">
    <ValidationObserver v-slot="{ invalid }">
      <form class="box" @submit.prevent="submitLogin">
        <ValidationProvider
          :name="$t('username')"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field
            class="my-3"
            :label="$t('username')"
            :type="{ 'is-danger': errors.length > 0 }"
          >
            <b-input v-model="form.username" id="username"></b-input>
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('password')"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field
            class="my-3"
            :label="$t('password')"
            :type="{ 'is-danger': errors.length > 0 }"
          >
            <b-input
              v-model="form.password"
              type="password"
              id="password"
            ></b-input>
          </b-field>
        </ValidationProvider>

        <b-field class="has-text-centered">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
          <b-button
            class="button is-link my-2"
            native-type="submit"
            :disabled="invalid"
            id="login_button"
            expanded
            >{{ $t("login") }}</b-button
          >
        </b-field>

        <p class="has-text-danger" v-if="errorMsg">
          {{ errorMsg }}
        </p>
      </form>
    </ValidationObserver>
  </div>
</template>