<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ApplicationListComponent",
  data() {
    return {
      applicationList: [],
      page: 1,
      total: 0,
      perPage: 10,
      isLoading: false,
      selectedStatus: "",
      statusList: [
        {
          value: "",
          text: "Все",
        },
        {
          value: "sent",
          text: "Отправлено",
        },
        {
          value: "verified",
          text: "Проверено деканом",
        },
        {
          value: "rejected",
          text: "Отклонено",
        },
        {
          value: "need_correction",
          text: "Требуется корректировка",
        },
        {
          value: "expired",
          text: "Просрочено",
        },
      ],
      selectedCategory: null,
      categoryList: [],
      selectedYear: null,
      yearList: [],
    };
  },
  mounted() {
    this.getCategoryList();
    this.generateYears();
    this.getStudentApplicationList();
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    getStudentApplicationList() {
      this.isLoading = true;
      let filterURL = `applications?page=${this.page}&status=${this.selectedStatus}&year=${this.selectedYear}`;

      if (this.selectedCategory) {
        filterURL += `&category=${this.selectedCategory}`;
      }
      axios
        .get(filterURL)
        .then((res) => {
          this.applicationList = res.data.results;
          this.total = res.data.count;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.page = 1;
          this.total = 0;
        });
    },

    onPageChange(page) {
      this.page = page;
      this.getStudentApplicationList();
    },

    getCSV() {
      let filterURL = `csv?page=${this.page}&status=${this.selectedStatus}&year=${this.selectedYear}`;

      if (this.selectedCategory) {
        filterURL += `&category=${this.selectedCategory}`;
      }

      axios.get(filterURL, { responseType: "blob" }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");

        let date = moment(new Date()).format("DD-MM-YYYY-hh-mm-ss");

        let filename = "applications_" + date + ".csv";

        link.href = url;
        link.setAttribute("download", filename); // непонятно для чего value

        document.body.appendChild(link);

        link.click();
      });
    },

    getCategoryList() {
      axios.get("categories").then((res) => {
        this.categoryList = res.data;
      });
    },

    generateYears() {
      const currentYear = new Date().getFullYear();

      this.selectedYear = currentYear;

      for (let i = 0; i < 3; i++) {
        this.yearList.push(currentYear - i);
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="is-flex is-flex-direction-row is-flex-wrap-wrap">
      <b-field label="Категория" class="mr-5" style="max-width: 300px">
        <b-select
          placeholder="Категория"
          v-model="selectedCategory"
          @input="getStudentApplicationList"
        >
          <option :value="null" :key="null">Все</option>
          <option
            v-for="category in categoryList"
            :value="category.id"
            :key="category.id"
          >
            {{ localize(category, "title") }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Статус" class="mr-5" style="max-width: 300px">
        <b-select
          :placeholder="$t('filter_status')"
          v-model="selectedStatus"
          @input="getStudentApplicationList"
        >
          <option
            v-for="status in statusList"
            :value="status.value"
            :key="status.value"
          >
            {{ $t(status.value) }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Год">
        <b-select
          :placeholder="$t('year')"
          v-model="selectedYear"
          @input="getStudentApplicationList"
        >
          <option v-for="year in yearList" :value="year" :key="year">
            {{ year }}
          </option>
        </b-select>
      </b-field>

      <b-button
        v-if="user && (user.role === 'secretary' || user.role === 'commission')"
        class="button is-align-self-flex-end"
        style="margin-left: auto"
        @click="getCSV()"
      >
        Скачать файл
      </b-button>
    </div>

    <b-table
      :data="applicationList"
      :loading="isLoading"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
    >
      <b-table-column
        field="category_title"
        v-slot="props"
        label="Категория"
        width="180px"
      >
        <span class="is-size-6">
          {{ localize(props.row.category, "title") }}
        </span>
      </b-table-column>
      <b-table-column field="applicant_name" v-slot="props" label="ФИО">
        {{ props.row.applicant.last_name }}
        {{ props.row.applicant.first_name }}
        {{ props.row.applicant.patronymic }}
      </b-table-column>
      <b-table-column field="profession" v-slot="props" label="Специальность">
        {{ localize(props.row.applicant.profession, "name") }}
      </b-table-column>
      <b-table-column field="status" v-slot="props" label="Статус">
        {{ $t(props.row.status) }}
      </b-table-column>
      <b-table-column field="sent_date" v-slot="props" label="Дата подачи">
        {{ props.row.sent_date | formatDate }}
      </b-table-column>
      <b-table-column v-slot="props">
        <router-link
          :to="{
            name:
              user.role === 'commission'
                ? 'ApplicationReadOnly'
                : 'ApplicationUpdate',
            params: { id: props.row.id, applicationProp: props.row },
          }"
          class="button is-primary is-small"
          target="_blank"
        >
          Посмотреть
        </router-link>
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">Заявления не найдены ;c</div>
      </template>
    </b-table>
  </div>
</template>
