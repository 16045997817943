<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ApplicationListComponent from "@/views/Dashboard/Management/ApplicationListComponent";

export default {
  name: "Home",
  components: {
    ApplicationListComponent,
  },
  data() {
    return {
      studentApplication: null,
      submissionPeriod: null,
      errorMsg: null,
      isToSAccepted: false,
      isLoading: false,
      hasClickedTermsLink: false,
      dashboardFiles: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.getStudentApplication();
    this.getSubmissionPeriod();
    this.getDashboardFiles();
  },
  methods: {
    getStudentApplication() {
      if (this.user.role === "student") {
        axios.get("applications/my-application").then((res) => {
          this.studentApplication = Object.keys(res.data).length === 0 ? null : res.data;
        });
      }
    },
    getSubmissionPeriod() {
      axios
        .get("submission_period")
        .then((res) => {
          this.submissionPeriod = res.data;
        })
        .catch((error) => {
          this.errorMsg = error.response.data.message;
        });
    },
    // TODO: вынести это в плагин
    getApplicationStatusClass(status) {
      switch (status) {
        case "sent":
          return "is-info";
        case "verified":
          return "is-success";
        case "need_correction":
          return "is-warning";
        case "rejected":
          return "is-danger";
        default:
          return "is-primary";
      }
    },

    getDashboardFiles() {
      axios
        .get("dashboard-files/")
        .then((res) => {
          this.dashboardFiles = res.data;
        })
        .catch((error) => {
          this.errorMsg = error.response.data.message;
        });
    },
  },
};
</script>

<template>
  <div class="home">
    <div
      v-if="dashboardFiles && dashboardFiles.order_for_granting"
      class="notification is-success has-text-centered"
    >
      <a :href="dashboardFiles.order_for_granting">
        <strong>
          Приказ на предоставление льготы за обучение 2024-2025 уч. год.
        </strong>
      </a>
    </div>
    <template v-if="submissionPeriod">
      <div
        v-if="submissionPeriod.is_open"
        class="notification is-success has-text-centered"
      >
        {{ $t("period_date") }}:
        <strong>{{ submissionPeriod.submission_period.start_date }}</strong> -
        <strong>{{ submissionPeriod.submission_period.end_date }}</strong>
      </div>
      <div v-else class="notification is-danger has-text-centered">
        {{ $t("period_is_closed") }}
      </div>
      <p class="has-text-centered-desktop">
        <a
          :href="localize(dashboardFiles, 'regulation')"
          target="_blank"
          @click="hasClickedTermsLink = true"
        >
          {{ $t("terms_file_name") }}
        </a>
      </p>
    </template>
    <template v-if="errorMsg">
      <div class="notification is-danger has-text-centered">
        {{ $t("no_period") }}
      </div>
    </template>

    <template
      v-if="
        user &&
        user.role === 'student' &&
        submissionPeriod &&
        submissionPeriod.is_open
      "
    >
      <div class="my-5">
        <div class="columns is-centered">
          <div class="column is-8">
            <p class="has-text-centered-desktop">
              {{ $t("welcome_heading") }}
            </p>
            <br />
            <p>
              {{ $t("welcome_text") }}
            </p>
            <br />
            <p class="has-text-centered-desktop">
              <a
                :href="localize(dashboardFiles, 'regulation')"
                target="_blank"
                @click="hasClickedTermsLink = true"
              >
                {{ $t("terms_file_name") }}
              </a>
            </p>
          </div>
        </div>
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
        <div class="columns is-centered">
          <div class="column is-4">
            <template v-if="!studentApplication">
              <b-field class="has-text-centered">
                <b-checkbox
                  :disabled="!hasClickedTermsLink"
                  type="is-info"
                  v-model="isToSAccepted"
                >
                  {{ $t("terms_service") }}
                </b-checkbox>
              </b-field>
              <router-link
                tag="button"
                class="button is-info is-fullwidth"
                :to="{ name: 'ApplicationCreate' }"
                :disabled="!isToSAccepted"
              >
                {{ $t("send_application") }}
              </router-link>
            </template>
            <router-link
              v-else
              tag="div"
              to="/"
              class="box notification"
              :class="getApplicationStatusClass(studentApplication.status)"
            >
              <div class="heading">
                {{ localize(studentApplication.category, "title") }}
              </div>
              <div class="title">
                {{ $t("application") }} (№{{ studentApplication.id }})
              </div>
              <div>
                <div class="heading">{{ $t("status") }}</div>
                <div class="title is-5">
                  {{ $t(studentApplication.status) }}
                </div>
              </div>
              <div class="mt-4 has-text-centered">
                <router-link
                  @click.native="isLoading = true"
                  :to="{ name: 'StudentApplicationDetails' }"
                  tag="button"
                  class="button is-fullwidth is-inverted"
                  :class="getApplicationStatusClass(studentApplication.status)"
                  >{{ $t("details") }}
                </router-link>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <template
      v-else-if="
        user &&
        (user.role === 'dean' ||
          user.role === 'secretary' ||
          user.role === 'commission')
      "
    >
      <ApplicationListComponent />
    </template>
    <template v-else-if="user && user.role === 'secretary'">
      <!-- TODO: список заявлений, которые прошли проверку деканом??? -->
      {{ $t("secretary") }}
    </template>
  </div>
</template>

<style>
.notification {
  padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
}
</style>
