<script>
import NavbarComponent from "@/components/Navbar.vue";

export default {
  components: {
    NavbarComponent,
  },
};
</script>

<template>
  <div id="app">
    <NavbarComponent />
    <section class="container">
        <router-view />
    </section>
  </div>
</template>

<style lang="scss">
</style>
