const cssHelper = {
  install(Vue) {
    /**
     * @param {*} documentStatus - статус документа

     * Возвращает массив: [CSS класс для текста (Bulma), тип цвета фона (Buefy), иконка (Material)]
     */
    Vue.prototype.getCSSClassAndIconForDocumentStatus = (status) => {
      switch (status) {
        case "not_checked":
          return ["has-text-warning", "is-warning", "alarm"];
        case "approved":
          return [
            "has-text-success",
            "is-success",
            "check-circle-outline",
          ];
        case "rejected":
          return [
            "has-text-danger",
            "is-danger",
            "close-circle-outline",
          ];
        default:
          return "";
      }
    };
  },
};

export default cssHelper;
