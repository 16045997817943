import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "@/assets/global.css";
import kk from "@/assets/i18n/kk.json";
import ru from "@/assets/i18n/ru.json";
import VueI18n from "vue-i18n";
import moment from "moment";

import cssHelper from "./helpers.js";

require("@/store/subscriber.js");

Vue.config.productionTip = false;
axios.defaults.baseURL = "https://discounts.shokan.edu.kz/api/v1/";

Vue.use(Buefy);
Vue.use(VueI18n);
Vue.component("ValidationProvider", ValidationProvider);
Vue.mixin({
    methods: {
        localize: function (obj, fieldName) {
            return obj[`${fieldName}_${this.$i18n.locale}`];
        },
    },
});
Vue.filter("formatDate", function (value) {
    if (value) {
        return moment(String(value)).format("DD.MM.YYYY, hh:mm");
    }
});
Vue.use(cssHelper);
const i18n = new VueI18n({
    locale: localStorage.getItem("language") || "kk",
    messages: {
        kk: kk,
        ru: ru,
    },
});

store.dispatch("auth/attempt", localStorage.getItem("token")).finally(() => {
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount("#app");
});
